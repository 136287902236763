"use client"

import { SessionProvider, signOut, useSession } from "next-auth/react"
import { usePathname, useRouter } from "next/navigation"
import React, { FunctionComponent, useEffect } from "react"
import { ONE_MINUTE } from "utils/consts"
import signOutUrl, { Reason } from "utils/signOutUrl"

const SessionWrapper = ({ children }: { children: React.ReactNode }) => {
  const { data, status, update } = useSession()
  const pathname = usePathname()
  const router = useRouter()

  useEffect(() => {
    if (status === "loading") {
      return
    }

    const isOnAuthRoute = pathname.startsWith("/auth/")

    if ((status === "unauthenticated" || !data) && !isOnAuthRoute) {
      router.push(
        signOutUrl({
          reason: "NOT_AUTHORISED",
          currentUrl: new URL(pathname, window.location.origin),
        }),
      )
      return
    }

    if (data?.error) {
      void signOut({
        redirect: !isOnAuthRoute,
        callbackUrl: signOutUrl({
          reason: data.error as Reason,
          currentUrl: new URL(pathname, window.location.origin),
        }),
      })
    }
  }, [status, data])

  useEffect(() => {
    const interval = setInterval(async () => {
      void update({ poll: true })
    }, ONE_MINUTE)
    return () => clearInterval(interval)
  }, [update])

  return children
}

const withSessionProvider = <P extends object>(
  Component: FunctionComponent<P>,
): FunctionComponent<P> => {
  const hoc: FunctionComponent<P> = (props: P) => (
    <SessionProvider>
      <Component {...props} />
    </SessionProvider>
  )

  hoc.displayName = `withSessionProvider(${Component.displayName || Component.name || "unknown"})`

  return hoc
}

export default withSessionProvider(SessionWrapper)
